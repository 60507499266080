<template>
  <div>
    <div class="header">
      <nav>
        <div class="logo">
          <img style="top: 2px; position: relative;" src="@/assets/images/logo.svg" class="logoimg" alt="Medly.chat Logo" />
          <span style="
              top: -5px;
              position: relative;
              font-size: 19px;
              font-weight: 600;
            ">Medly.chat</span>
          <!-- <a
            id="mobile"
            class="btn-click"
            href="#"
            style="
              display: none;
              padding: 5px 10px;
              font-size: 15px;
              top: -10px;
              margin-left: 15px;
            "
            >{{ $t("probar") }} →</a
          > -->
        </div>
        <!-- <input type="checkbox" id="menu-toggle" />
        <label for="menu-toggle" class="menu-icon">&#9776;</label>
        <ul class="menu">
          <li><a href="#explora">#Explora</a></li>
          <li><a href="#global">#Global</a></li>
          <li><a href="#esperanza">#Esperanza</a></li>
          <li><a href="#info">Información</a></li>
          <li>
            <LangDrop />
          </li>
        </ul> -->
      </nav>
    </div>
    <header style="margin-top: 80px;">
      <img class="logo" src="@/assets/images/logo.svg" alt="Biblia.chat Logo" />
      <h1>{{ $t("consigue") }}</h1>
      <p style="
          text-align: center;
          margin: 26px auto;
          font-size: 16px;
          line-height: 20px;
        ">
        {{ $t("des1") }}<br />
        {{ $t("des2") }}
      </p>
      <p style="
          text-align: center;
          margin: 16px auto;
          font-size: 16px;
          color: red;
        ">
        <b>
          "Solo para Estudiantes y Profesionales de la salud"
        </b>
      </p>
      <div class="apple-buttons">
        <a href="https://play.google.com/store/apps/details?id=redmasiva.medlychat" target="_blank"><img
            alt="descargar Medly.chat" src="@/assets/images/google-play.png" style="margin-right: 20px" /></a>
        <a href="https://apps.apple.com/app/medly-chat/id6471679290" target="_blank"><img
            alt="descargar Medly.chat en App Store" style="height: 48px" src="@/assets/images/app-store.svg" /></a>
      </div>
      <!-- <a class="button-web" href="https://app.biblia.chat/"
        >O usar Biblia.chat en línea
        <svg
          fill="none"
          height="22"
          role="img"
          viewBox="0 0 24 24"
          width="22"
          xmlns="http://www.w3.org/2000/svg"
          class="ms-1"
        >
          <path
            clip-rule="evenodd"
            d="M15.566 13a.25.25 0 0 1 .177.428L11.29 17.88a1 1 0 0 0 1.415 1.415l6.588-6.588a1 1 0 0 0 0-1.414l-6.588-6.588a.997.997 0 0 0-1.41 1.41l4.45 4.458a.25.25 0 0 1-.178.427H5a1 1 0 1 0 0 2h10.566Z"
            fill="currentColor"
            fill-rule="evenodd"
          ></path>
        </svg>
      </a> -->
    </header>
    <!-- <div id="explora" class="card image-left">
      <div class="iphone screenshot-wrapper">
        <div class="screenshot">
          <img src="@/assets/images/sources.png" alt="explora imagen" />
        </div>
      </div>
      <div class="paragraph-wrapper">
        <p>
          {{ $t("explora.paragraph") }}
        </p>
      </div>
    </div>

    <div id="global" class="card image-right">
      <div class="paragraph-wrapper">
        <p>
          {{ $t("global.paragraph") }}
        </p>
      </div>
      <div class="iphone screenshot-wrapper">
        <div class="screenshot">
          <img src="@/assets/images/sources2.png" alt="global imagen" />
        </div>
      </div>
    </div>

    <div id="esperanza" class="card image-left">
      <div class="iphone screenshot-wrapper">
        <div class="screenshot">
          <img src="@/assets/images/sources3.png" alt="Esperanza screenshot" />
        </div>
      </div>
      <div class="paragraph-wrapper">
        <p>
          {{ $t("esperanza.paragraph") }}
        </p>
      </div>
    </div>

    <div id="privacidad" class="card image-right">
      <div class="paragraph-wrapper">
        <p>
          {{ $t("privacidad.paragraph") }}
        </p>
      </div>
      <div class="iphone screenshot-wrapper">
        <div class="screenshot">
          <img src="@/assets/images/sources4.png" alt="Privacidad screenshot" />
        </div>
      </div>
    </div> -->

    <!-- <div id="info" class="questions">
      <div class="item">
        <p>
          {{ $t("info.item1.paragraph1") }}
        </p>
        <p style="margin-top: 10px">
          {{ $t("info.item1.paragraph2") }}
        </p>
        <p style="margin-top: 10px">
          {{ $t("info.item1.paragraph3") }}
        </p>
        <p style="margin-top: 10px">
          {{ $t("info.item1.paragraph4") }}
        </p>
        <p style="margin-top: 10px">
          {{ $t("info.item1.paragraph5") }}
          <a href="mailto:ramphysa@gmail.com" target="_blank">Ramphy</a>
        </p>
      </div>
      <div class="item">
        <div class="title">{{ $t("info.item2.title") }}</div>
        <p>
          {{ $t("info.item2.paragraph") }}
        </p>
      </div>
      <div class="item">
        <div class="title">{{ $t("info.item3.title") }}</div>
        <p>
          {{ $t("info.item3.paragraph1") }}
        </p>
        <p style="margin-top: 10px">
          {{ $t("info.item3.paragraph2") }}
        </p>
      </div>
    </div> -->
    <footer>
      <div class="flex-container" style="width: 100%; display: flex; justify-content: center;">
        <div style="width: 100%; display: flex; justify-content: center;">
          <span class="copyright">Medly.chat, 2023</span>
        </div>
        <div style="width: 100%; display: flex; justify-content: center;">
          <a href="mailto:contacto@medly.chat"><span>contacto@medly.chat</span></a>
        </div>
        <div style="width: 100%; display: flex; justify-content: center;">
          <a href="terms.html">Privacidad y Terminos</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// import LangDrop from '@/components/language.vue';
import { defineComponent } from "vue";
export default defineComponent({
  components: {
    // LangDrop,
  },
});
</script>
<style>
/* Estilos para pantallas grandes (mayores a 600px de ancho) */
@media (max-width: 600px) {
  .flex-container {
    flex-direction: column;
  }
}

/* Estilos para pantallas pequeñas (600px o menos de ancho) */
@media (min-width: 600px) {
  .flex-container {
    flex-direction: row;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: #f1f1f1;
  color: #333;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.dropdown-toggle .flag-icon {
  margin-right: 5px;
}

.dropdown-toggle .caret {
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 160px;
  display: none;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-menu.show {
  display: block;
}

.card p::first-letter {
  color: #333;
  font-weight: bold;
}

.button-web {
  text-decoration: none;
  font-weight: 700;
  text-align: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 2rem;
  border-bottom: none;
  font-size: 17px;
}

.btn-click {
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
  border-radius: 6px;
  transition: all 1000ms ease 0s;
  border: 2px solid #000;
  border-bottom-color: #000;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  position: relative;
  font-size: 17px;
  background-color: #000;
  color: rgb(255, 255, 255);
  transform: translateY(0px);
  padding: 7px 17px 14px;
}

.btn-click:hover {
  border-bottom-color: #3b82f6;
}

html,
body {
  font-size: 20px;
  color: #000;
  background-color: #fbfaf8;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  border-bottom: 2px solid #d5d5d3;
  text-decoration: none;
  color: inherit;
}

a:hover {
  border-bottom-color: #000;
}

.apple-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.apple-buttons a {
  display: inline-block;
  margin-right: 12px;
  border-bottom: none;
}

.apple-buttons a:last-child {
  margin-right: 0px;
}

.apple-buttons a img {
  display: block;
  height: 50px;
}

.apple-buttons a:hover {
  opacity: 0.75;
}

header {
  max-width: 578px;
  width: 100%;
  margin: 60px auto;
}

header h1 {
  font-weight: 800;
  font-size: 32px;
  color: #1a1c1f;
  text-align: center;
  margin: 26px auto;
  width: 100%;
}

header .logo {
  display: block;
  margin: 0px auto;
  width: 100px;
  height: 100px;
  border-radius: 23px;
  padding: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
}

header .apple-buttons {
  align-items: center;
  justify-content: center;
}

header .apple-buttons img {
  height: 60px;
}

.card {
  max-width: 976px;
  width: calc(100% - 32px);
  overflow: hidden;
  margin: 0px auto 40px auto;
  background-color: #fff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.13);
  border-radius: 56px;
}

.card.image-left,
.card.image-right {
  display: flex;
  flex-direction: row;
}

.card.image-left {
  padding: 52px 60px 0px 80px;
}

.card.image-left .paragraph-wrapper {
  margin-left: 80px;
}

.card.image-right {
  padding: 52px 80px 0px 60px;
}

.card.image-right .paragraph-wrapper {
  margin-right: 80px;
}

.card.wide-content {
  padding: 52px 60px 0px 60px;
}

.card.wide-content p {
  margin-bottom: 20px;
}

.card.wide-content .apple-buttons {
  margin-bottom: 40px;
}

.card p {
  font-size: 22px;
  line-height: 142%;
  color: #808084;
  font-weight: 400;
}

.card p strong {
  color: #000;
  font-weight: 800;
}

.card p a {
  padding-bottom: 1px;
}

.card .screenshot-wrapper {
  flex: none;
  align-self: flex-end;
}

.card .screenshot-wrapper.iphone {
  width: 318px;
  max-width: 100%;
}

.card .screenshot-wrapper.iphone .screenshot {
  padding-bottom: 122.012578%;
}

.card .screenshot-wrapper.wide {
  width: 100%;
}

.card .screenshot-wrapper.wide .screenshot {
  padding-bottom: 42.4757%;
}

.card .screenshot-wrapper.shadow {
  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.08));
}

.card .screenshot-wrapper .screenshot {
  position: relative;
}

.card .screenshot-wrapper .screenshot img {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
}

.card .paragraph-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.questions {
  max-width: 743px;
  width: calc(100% - 40px);
  margin: 0px auto 0px auto;
  padding-top: 60px;
}

.questions .item {
  margin-bottom: 40px;
}

.questions .item:last-child {
  margin-bottom: 0px;
}

.questions .item .title {
  font-weight: 800;
  font-size: 21px;
  line-height: 140%;
  margin-bottom: 4px;
}

.questions .item p {
  line-height: 146%;
}

footer {
  max-width: 743px;
  width: calc(100% - 40px);
  margin: 70px auto 40px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-weight: 500;
  font-size: 17px;
  line-height: 140%;
}

footer .copyright,
footer a {
  margin-top: 10px;
  margin-right: 20px;
}

.layout.doc {
  max-width: 618px;
  width: calc(100% - 40px);
  margin: 32px auto;
}

.layout.doc p,
.layout.doc ul,
.layout.doc ol {
  margin: 16px 0px;
}

.layout.doc h4 {
  margin: 24px 0px 16px 0px;
}

.layout.doc ul,
.layout.doc ol {
  list-style-position: inside;
}

@media screen and (max-width: 760px) {
  header {
    max-width: 618px;
    width: calc(100% - 40px);
    margin: 32px auto 50px auto;
  }

  header h1 {
    font-size: 24px;
  }

  header .apple-buttons a {
    margin-right: 0px;
  }

  header .apple-buttons>.mac-app-store {
    display: none;
  }

  .card {
    border-radius: 46px;
    align-items: center;
    justify-content: center;
  }

  .card.image-left,
  .card.image-right,
  .card.wide-content {
    flex-direction: column;
    padding: 32px 32px 0px 32px;
  }

  .card.image-left .paragraph-wrapper,
  .card.image-right .paragraph-wrapper,
  .card.wide-content .paragraph-wrapper {
    margin-left: 0px;
    margin-right: 0px;
  }

  .card.wide-content .apple-buttons {
    margin-bottom: 32px;
    flex-direction: column;
  }

  .card.wide-content .apple-buttons a {
    margin-right: 0px;
    margin-bottom: 12px;
  }

  .card.wide-content .apple-buttons a:last-child {
    margin-bottom: 0px;
  }

  .card.wide-content .apple-buttons img {
    height: 50px;
  }

  .card .screenshot-wrapper {
    align-self: center;
  }

  .card .paragraph-wrapper {
    margin-bottom: 32px;
  }

  .card p {
    font-size: 19px;
  }

  .card.image-left {
    flex-direction: column-reverse;
  }

  .questions .item {
    margin-bottom: 36px;
  }

  .questions .item .title {
    font-size: 20px;
    margin-bottom: 2px;
  }

  .questions .item p {
    font-size: 19px;
  }

  footer .copyright,
  footer a {
    margin-right: 16px;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  box-shadow: 0 4px 20px hsla(207, 24%, 35%, 0.1);
  background-color: #fff;
  z-index: 1;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  max-width: 976px;
  margin: auto;
}

.logo a {
  font-size: 24px;
}

.logo a span {
  color: #8739fa;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu a {
  display: block;
  padding: 7px 15px;
  font-size: 17px;
  font-weight: 500;
  transition: 0.2s all ease-in-out;
  color: #333;
}

.menu-icon {
  display: none;
}

#menu-toggle {
  display: none;
}

#menu-toggle:checked~.menu {
  transform: scale(1, 1);
}

@media only screen and (max-width: 950px) {
  .logoimg {
    margin-left: 15px;
  }

  .menu {
    flex-direction: column;
    background-color: #fff;
    align-items: center;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 1;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  .menu a {
    margin-left: 12px;
  }

  .menu li {
    margin-bottom: 10px;
  }

  .menu-icon {
    display: block;
    color: #333;
    font-size: 28px;
    cursor: pointer;
    margin-right: 10px;
    top: -2px;
    position: relative;
  }
}

.logoimg {
  width: 32px;
  margin-right: 5px;
}</style>
